.paymentAgain {
  width: rem(160px);
  border-radius: rem(4px);
  overflow: hidden;
  display: inline-block;
  margin-right: rem(20px);
  margin-bottom: rem(20px);
  cursor: pointer;
}

.paymentAgainContainer {
  display: flex;
  flex-wrap: wrap;
}

.paymentAgain__top {
  padding: rem(10px) rem(13px) rem(8px);
  min-height: rem(66px);

  &.background-color-category-PostalBills {
    background-color: #D7CECC;
  }
  &.background-color-category-OtherServices {
    background-color: #C9BCDB;
  }
  &.background-color-category-OtherPayments {
    background-color: #C3DCF1;
  }
  &.background-color-category-INPS {
    background-color:#B6DFC5;
  }
  &.background-color-category-CBill {
    background-color:#FFEE95;
  }
  &.background-color-category-RAV_CBILL {
    background-color:#FFEE95;
  }
  &.background-color-category-F24 {
    background-color:#F8D1DF;
  }
  &.background-color-category-Recharge {
    background-color:#DBE0D1;
  }
  &.background-color-category-banktransfer {
    background-color:#D9E6E1;
  }
}

.paymentAgain__title {
  @include gmb-text-subtitle;
  text-transform: capitalize;
  color: $gmb-color-default;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.paymentAgain__middle {
  background: $gmb-color-k7;
  position: relative;
  padding: rem(10px);
}
.paymentAgain__bottom {
  background: $gmb-color-k7;
  position: relative;
  padding: rem(10px);
}

.paymentAgain__detail {
  color: $gmb-color-default;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paymentAgain__amount  {
  @include gmb-text-valuta;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 18px);
  display: inline-block;
  vertical-align: bottom;
}

.paymentAgain__aliasAccount  {
  @include gmb-text-info;
}

.paymentAgain__ibanAccount {
  @include gmb-text-info;
}

.paymentAgain__currency {
  @include gmb-text-subtitle;
  display: inline-block;
  vertical-align: bottom;
  margin-left: rem(5px)
}

.paymentAgain__date  {
  @include gmb-text-info;
  display: block;
}

.paymentAgain__info {
  margin-bottom: rem(5px);
}

.paymentAgain__name {
  @include gmb-text-info;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.paymentAgain__iban {
  font-size: rem(10px);
  @include gmb-text-regular;
  line-height: rem(16px);
  text-transform: uppercase;
}
