$modal_background_color: rgb(0, 0, 0, 0.8);
$modal_max_width: 1024px;
$modal_min_width: 500px;

// prevent scrolling of the background when a modal is opened
body.gmb-modal-open {
  overflow: hidden;
}

gmb-modal {
  display: none;
}

gmb-modal.modal-open {
  width: 100%; /* Full width */
  box-sizing: border-box;
  display: block;
  position: relative;
  @include gmb-text-body;

  .gmb-modal-container {
    background-color: $modal_background_color;
    height: 100%;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    position: fixed; /* Stay in place */
    top: 0;
    width: 100%; /* Full width */
    z-index: 1000; /* Sit on top */

    /* Modal Content */
    .gmb-modal-wrapper {
      background-color: transparent;
      box-sizing: border-box;
      height: 100%;
      margin: auto;
      min-width: $modal_min_width;
      max-width: $modal_max_width;
      padding-top: 40px;
      width: 100%;
      opacity: 0;

      &.no-animation {
        opacity: 1;
      }

      &.open-animation {
        animation: openCenterModalComponent 0.3s ease-in forwards;
      }

      &.close-animation {
        animation: closeCenterModalComponent 0.3s ease-in forwards;
      }

      .gmb-modal-content-container {
        @include inline-block-element(true);
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: rem(60px);
        padding-top: rem(55px);
        padding-bottom: rem(40px);
        min-height: 100%;
        box-sizing: border-box;
        position: relative;

        .close-icon-container {
          text-align: right;
          position: absolute;
          top: rem(32px);
          right: rem(32px);
          z-index: 900;

          > .close-icon {
            @include gmb-text-paragraph-uppercase;
            color: $gmb-color-default;
	          text-decoration: none;
            cursor: pointer;

            &:hover, &:focus, &:visited {
              color: $gmb-color-default;
            }
          }
        }

        .gmb-modal-content {
          @include inline-block-element(true);
        }

      }

    }

  }
}

.gmb-modal-title {
  @include gmb-text-headline;
  color: $gmb-color-default;
  margin-top: 0;
  padding-top: 0;
}
.gmb-modal-subtitle {
  @include gmb-text-subtitle;
  color: $gmb-color-default;
}
.gmb-modal-paragraph {
  @include gmb-text-body;
  color: $gmb-color-default;
}

.modal-center-screen-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow-y: auto;

  .modal-center-screen {
    left: 50%;
    margin-top: 0 !important;
    transform: translate(-50%, -50%);
    width: rem(850px);
    position: relative;
    overflow: visible;

    .modal-image-head {
      margin: rem(-40px);
      padding: rem(40px);
      margin-bottom: rem(20px);
      min-height: rem(220px);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
    }

    .modal-content {
      border-radius: rem(20px);
      background-color: $gmb-color-white !important;
    }
  }
}

.modal-close-background-container {
  position: absolute;
  top: 0;
  right: 0;
  width: rem(42px);
  height: rem(42px);
  background: rgba(38,38,38,0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-close-icon {
    font-size: rem(18px);
    color: $gmb-color-white;
  }
}

@keyframes openCenterModalComponent {
	0% { opacity: 0; padding-top: 120px; }
	100% { opacity: 1; padding-top: 40px; }
}

@keyframes closeCenterModalComponent {
  0% { opacity: 1; padding-top: 40px; }
	100% { opacity: 0; padding-top: 120px; }
}
