$message-arrow-height: 10px;

gmb-jsf-message{
  @include inline-block-element;
  width: 100%;
  position: relative;

  .gmbJsfMessageBody{
    position: relative;
    display: block;
    white-space: normal;

    .gmbJsfMessageBox{

      display: block;
      box-sizing: border-box;
      width: 100%;
      position: absolute;
      font-size: 16px;
      text-align: left;
      color: #FFF;
      border-radius: 4px;
      z-index: 2;
      min-width: 120px;
      left: 0;
      top: $message-arrow-height;
      min-height: 50px;
      padding: 8px;


      &:after{
        border-color: transparent transparent transparent transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-style: solid;
        border-width: 11px;
        margin-top: -$message-arrow-height;
        left: 10px;
        top: -$message-arrow-height;
      }

      &[open=true]{
        display: block;
      }

      &.error-message{
        background-color: $gmb-color-negative;
      }

      &.info-message{
        background-color: #4e964e;
      }

      &.warning-message{
        background-color: #eaad24;
      }

      &.warning-message::after {
        border-color: transparent transparent #eaad24 transparent;
      }

      &.error-message::after {
        border-color: transparent transparent $gmb-color-negative transparent;
      }

      &.info-message::after {
        border-color: transparent transparent #4e964e transparent;
      }
    }

  }


}


.highlight-gmb-message-info{
  border: 2px solid #00A197;
}

.highlight-gmb-message-warning{
  border: 2px solid yellow;
}

.highlight-gmb-message-error{
	border: 2px solid #E2001A;
}

.highlight-gmb-message-fatal{
  border: 2px solid #E2001A;
}
