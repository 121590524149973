$gmb-color-default: #262626;
$gmb-color-secondary: #666666;
$gmb-color-disabled: #cccccc;
$gmb-color-accent: #007a91;
$gmb-color-positive: #2fa358;
$gmb-color-negative: #D73928;
$gmb-color-warning: #fbb800;
$gmb-color-link-visited: #7757A4;
$gmb-color-b7: #006375;
$gmb-color-k6: #e5e5e5;
$gmb-color-k7: #f5f5f5;
$gmb-color-b2: #bfebf3;
$gmb-color-pfm-assicurazioni-finanziamenti:#CCDB29;
$gmb-color-pfm-all: #21A5BB;
$gmb-color-pfm-tasse: #7757A4;
$gmb-color-pfm-casa: #87BAE4;
$gmb-color-pfm-utenze: #9E9F36;
$gmb-color-pfm-trasporti: #F58523;
$gmb-color-pfm-scuola: #5E3D32;
$gmb-color-pfm-salute: #007A6B;
$gmb-color-pfm-shopping: #FAAA18;
$gmb-color-pfm-cura-persona: #DD1860;
$gmb-color-pfm-tempo-libero-viaggi: #388BCA;
$gmb-color-pfm-alimentari-spesa: #A33694;
$gmb-color-pfm-altre-spese: #535453;
$gmb-color-pfm-investimenti: #004C3D;
$gmb-color-pfm-contanti: #FFD90D;
$gmb-color-pfm-contabilita-interna-spese: #333333;
$gmb-color-pfm-contabilita-interna-entrate: #333333;
$gmb-color-pfm-entrate: #2fa358;

$gmb-color-investments-positive: #2E844D;

$gmb-color-contact-box-pink: #c9bcdb4d;
$gmb-color-contact-box-blue: #c3dcf14d;

$gmb-color-action-bar-background: #244858;

$gmb-color-cards-flexia: #961414;

$gmb-color-greenlite: #D9E6E1;
$gmb-color-white: #fff;

$gmb-color-modal-label: #909090;

%gmb-color-bg-main {
  color: $gmb-color-default;
  background-color: $gmb-color-white;

}

%gmb-color-bg-gray {
  color: $gmb-color-default;
  background-color: $gmb-color-k7;
}


%gmb-color-bg-black {
  color: $gmb-color-white;
  background-color: $gmb-color-default;
}


%gmb-color-bg-blue {
  color: $gmb-color-white;
  background-color: $gmb-color-accent;
}

@mixin gmb-cta-primary{
  background: $gmb-color-accent;
  color: $gmb-color-white;
  border: 2px solid $gmb-color-accent;

  &:hover{
    background-color: $gmb-color-b7;
    border-color: $gmb-color-b7;
  }
}


@mixin gmb-cta-secondary{
  background: $gmb-color-white;
  color: $gmb-color-default;
  border: 2px solid $gmb-color-default;

  &:hover{
    background-color: $gmb-color-k7;
    border-color: $gmb-color-secondary;
    color: $gmb-color-secondary;
  }
}

@mixin gmb-cta-secondary-negative{
  color: $gmb-color-white;
  border: 2px solid $gmb-color-white;

  &:hover{
    border-color: $gmb-color-disabled;
    color: $gmb-color-disabled;
  }
}


@mixin gmb-cta-disabled{
  background: $gmb-color-k7;
  color: $gmb-color-disabled;
  border: 2px solid $gmb-color-k7;
  cursor: default;
  pointer-events: none;
}
