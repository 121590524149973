//.gmb-typ-title -> gmb-typ-display
.gmb-typ-display {
  @include gmb-text-display;
}

.gmb-typ-headline {
  @include gmb-text-headline;
}

.gmb-typ-headline-regular {
  @include  gmb-text-headline-regular;
}

.gmb-typ-importo {
  @include gmb-text-importo;
}

.gmb-typ-valuta {
  @include gmb-text-valuta;
}

.gmb-typ-valuta-regolar {
  @include gmb-text-valuta-regular;
}

.gmb-typ-valuta-regolar-uppercase {
  @include gmb-text-valuta-regular-uppercase;
}

//gmb-typ-paragraph-title-upper -> gmb-typ-paragraph-uppercase
.gmb-typ-paragraph-uppercase {
  @include gmb-text-paragraph-uppercase;
}

.gmb-typ-paragraph {
  @include gmb-text-paragraph;
}


.gmb-typ-subtitle {
  @include gmb-text-subtitle;
}

.gmb-typ-subtitle-uppercase {
  @include gmb-text-subtitle-uppercase;
}

.gmb-typ-body {
  @include gmb-text-body;
}


.gmb-typ-dashboard {
  @include gmb-text-dashboard;
}

.gmb-typ-info {
  @include gmb-text-info;
}

.gmb-typ-label-upper {
  @include gmb-text-label-upper;
}

.gmb-typ-label-lower {
  @include gmb-text-label-lowercase;
}

.gmb-typ-dashboard-highlight {
  @include gmb-text-dashboard-highlight;
}

.gmb-typ-dashboard-text {
  @include gmb-text-dashboard-text;
}

.gmb-typ-dashboard-close {
  @include gmb-text-dashboard-close;
}

.gmb-typ-disclaimer {
  @include gmb-text-dislacimer;
}

.gmb-typ-modal-label {
  @include gmb-modal-label;
}

.gmb-typ-modal-value {
  @include gmb-modal-value;
}

.gmb-typ-capitalize {
  @include gmb-text-capitalized;
}
