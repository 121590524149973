gmb-tab-button {
  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;

  &[disabled=true] {
    > .tab-button, > .tab-button:hover {
      cursor: default;
      pointer-events: none;
      color: $gmb-color-disabled;
    }
  }

  .tab-button {
    text-decoration: none;
    border-bottom: 3px solid transparent;
    display: inline-block;
    max-width: 100%;
    color: $gmb-color-default;

    .tab-title, .tab-subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tab-title {
      @include gmb-text-paragraph;
      text-transform: capitalize;
      margin-bottom: rem(5px);
    }

    .tab-subtitle {
      @include gmb-text-label-upper;
      margin-bottom: rem(5px);
    }

    &:hover {
      color: $gmb-color-secondary;

      ~#arrow_selection_div {
        color: $gmb-color-secondary;
      }
    }

    &.tab-button-clicked {
      color: $gmb-color-accent;

      &.underline-on-active {
        border-bottom: 3px solid $gmb-color-accent;
      }
    }
  }
}

// STYLE FOR WRAPPE

.tab-container {
  display: flex;
  justify-content: space-between;
	position: relative;
	background-color: $gmb-color-white;
	width: 100%;
  padding-left: rem(20px);
  padding-right: rem(20px);
}

.tabs_button_wrapper {
	margin: rem(10px) rem(60px) rem(10px) 0px;
  max-width: rem(130px);
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  position: relative;

    .tabs_button_icon {
      vertical-align: top;
      display: inline-block;
      margin-top: rem(5px);
      margin-left: rem(16px);
      color: $gmb-color-default;

     .UXUI-icon.Arrow-Up {
        display: none;
      }

      &.selected .UXUI-icon.Arrow-Up {
          display: block;
          color: $gmb-color-default;
      }

      &.selected .UXUI-icon.Arrow-Down {
          display: none;
      }

    }
}

