$gmb-radio-button-height: rem(22px);
$gmb-radio-button-width:rem(22px);

gmb-radio-button {

  /* The container */
  .gmb-radio-container {

    display: flex;
    align-items: start;

    .gmb-radio-wrapper {
      display: inline-block;
      position: relative;
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      text-align: center;
      vertical-align: middle;
      margin-right: rem(11px);

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: rem(22px);
        width: rem(22px);
        margin-top: 0;
        /*display: none;*/
      }

      .gmb-checkmark {
        @include inline-block-element;
        position: relative;
        cursor: pointer;
        height: $gmb-radio-button-height;
        width: $gmb-radio-button-width;
        background-color: white;
        border: solid 1px $gmb-color-default;
        border-radius: 50%;
        pointer-events: none;
      }

      /* On mouse-over, add a background color */
      &:hover input ~ .gmb-checkmark, &:focus input ~ .gmb-checkmark {
        border: solid 1px $gmb-color-secondary;
        background-color: $gmb-color-k7;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .gmb-checkmark:after {
        content: "";
        display: none;
        height: rem(10px);
        width: rem(10px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        background: white;
      }

      /* Show the indicator (dot/circle) when checked */
      input:checked ~ .gmb-checkmark:after {
        background-color: $gmb-color-accent;
        display: inline-block;
      }

      input[disabled] ~ .gmb-checkmark {
        cursor: default;
        pointer-events: none;
        border: solid 1px $gmb-color-disabled;
        background-color: $gmb-color-k7;
      }

      input:not(:checked) ~ .gmb-radio-error {
        border: solid 2px $gmb-color-negative;
      }
    }

    .gmb-radio-label-wrapper {
      display: inline-block;
      vertical-align: top;
      @include gmb-text-body;
      line-height: rem(22px);

      &.gmb-radio-label-disabled {
        color: $gmb-color-disabled;
      }

      label{
        z-index: 1;
        margin-bottom: 0;
      }

      label span,label label{
        padding: 0px;
        pointer-events: none;
        line-height: rem(22px);
      }
    }
  }

  .gmb-radio-error-container {
    background-color: $gmb-color-negative;
    color: white;
    padding: rem(9px) rem(10px);
    @include gmb-text-body;
    text-align: left;
    margin-top: rem(9px);
  }

  .gmb-radio-wrapper input[type='radio']:focus + .gmb-checkmark{
    box-shadow: 0 0 8px $gmb-color-accent;
  }





}


