.modalTable {
  width: 100%;
}

.modalTable__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTable__title {
  display: block;
  @include gmb-text-headline;
  color: $gmb-color-default;
  &:first-child{
    max-width: 70%;
  }
}

.modalTable__secondary {
  display: block;
  @include gmb-text-label-upper;
  color: $gmb-color-secondary;
}

.modalTable__labelStatus  {
  @include gmb-text-label-upper;
  color: $gmb-color-secondary;
  position: relative;
  padding-left: rem(18px);
  &:before {
    content: '';
    display: block;
    height: rem(13px);
    position: absolute;
    width: rem(13px);
    left: 0;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
    &.pending,
    &.to_authorize,
    &.waiting_to_execute,
    &.not_cashin,
    &.suspended,
    &.workinprogress,
    &.recording,
    &.to_confirm,
    &.wait,
    &.to_send {
      &:before {
        background: $gmb-color-warning;
      }
    }
    &.executed,
    &.received,
    &.sent,
    &.recordered,
    &.active,
    &.prenotabile,
    &.active_not_confirmed {
      &:before {
        background: $gmb-color-positive;
      }
    }
    &.cancelled,
    &.revoked,
    &.canceled,
    &.deleted,
    &.revoked_from_agency  {
      &:before {
        background: $gmb-color-secondary;
      }
    }
    &.rejected,
    &.serviceExpired,
    &.expired,
    &.incomplete,
    &.non_prenotabile,
    &.blocked,
    &.error  {
      &:before {
        background: $gmb-color-negative
      }
    }
    &.reserved,
    &.booked{
      &:before {
        background :$gmb-color-accent;
      }
    }
}

.modalTable__img {
  margin: rem(20px) 0 rem(25px);
  display: block;
}

.modalTable__spacer  {
  padding-top: rem(32px);
  border-bottom: 1px solid $gmb-color-disabled;
  margin-bottom: rem(25px);
}

.modalTable__imagecontent {
  background-size: cover;
  height: rem(150px);
  border-radius: 3px;
  overflow: hidden;
}

.modalTable__list {
  margin-bottom: rem(15px);
}

.modalTable__item  {
  display: block;
  margin-bottom: rem(25px);
  &:last-child {
    margin-bottom: 0;
  }
}

.modalTable__titleList {
  display: block;
  color: $gmb-color-secondary;
  @include gmb-text-label-upper;
  margin-bottom: rem(3px);
}

.modalTable__description {
  display: block;
  @include gmb-text-body;
  color: $gmb-color-default;
}

.modalTable__type {
  display: flex;
  align-content: start;
  margin-bottom: rem(40px);
}

.modalTable__texts {
  margin-left: rem(20px);
}

.modalTable__icon {
  font-size: rem(20px);
  margin-top: rem(6px);
}
.modalTable__titleSection {
  margin: 0;
  @include gmb-text-subtitle;
  color: $gmb-color-default;
}

.modalTable__labels {
  margin: 0 0 rem(15px);
  @include gmb-text-label-upper;
  color: $gmb-color-secondary;
}

.modalTable__linkAccess {
  .link-access {
    margin-right: rem(20px)
  }
}

.modalTable__graphicContet  {
  margin-top: rem(15px)
}

.modalTable__label-currency {
  @include gmb-text-paragraph;
  margin-left: rem(5px);
  line-height: rem(32px);
}

.modalTable__amount-group {
  display: flex;
  align-items: flex-end;
  width: 30%;
  justify-content: flex-end;
}
