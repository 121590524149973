.calendar-full-version {
  position: relative;
  .calendar_container {
    right: 0;
    display: none;
    position: absolute;
    z-index: 100;
    width: rem(400px);
    top: rem(50px);
    padding: 0;
    background-color: white;
    border-radius: rem(4px);
    border: rem(1px) solid $gmb-color-disabled;
    box-shadow: 0 rem(2px) rem(4px) 0 rgba(0,0,0,0.5);
    //container relativo dal mese anno e ai numeri
    .ui-datepicker  {
      display: block;
      width: 100%;
      border-width: rem(1px) 0;
      padding: 0 rem(7px);
      border: 0;
      .ui-datepicker-title {
        line-height: rem(70px);
        margin: 0 auto;
      }
      .ui-datepicker-header {
        border: 0;
        background: #fff;
        margin-bottom: 0;
        a {
          &.ui-datepicker-prev {
              top: 50%;
              left: 40px;
              transform: translatey(-50%);
            }
          &.ui-datepicker-next {
              top: 50%;
              right: 40px;
              transform: translatey(-50%);
            }
          &.ui-datepicker-prevYear {
              top: 50%;
              left: 13px;
              transform: translatey(-50%);
              position: absolute !important;
            }
          &.ui-datepicker-nextYear {
              top: 50%;
              right: 13px;
              transform: translatey(-50%);
              position: absolute !important;
            }
        }
      }
      td {
        border-top: rem(3px) solid white;
        padding: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
        .ui-state-default  {
          @include gmb-text-label-lowercase;
          color: $gmb-color-default;
          width: rem(34px);
          height: rem(34px);
          line-height: rem(34px);
          vertical-align: middle;
          margin: 0 auto;
        }
        &.selected {
          background-color: $gmb-color-accent;
          &.selected-first,  &.startDate  {
            border-radius: rem(30px) 0 0 rem(30px);
          }
          &.selected-last,  &.endDate  {
            border-radius: 0 rem(30px) rem(30px) 0
          }
          &.selected-single {
            background-color: transparent;
          }
          &.selected-single>* {
            background-color: $gmb-color-accent;
            color: $gmb-color-white;
          }
          >a {
            color: $gmb-color-white;
          }
        }
        &.date-range-selected > a {
          color: $gmb-color-white;
        }

    }
  }

  }
  //parte conteiner relativa al input che apre il calendar
  .calendar-button-container{
    height: rem(40px);
    min-width: rem(43px);
    display: block
  }
  //parte relativa al input che apre il calendar
  .calendar-button {
    height: rem(40px);
    min-width: rem(43px);
    background-color: white;
    border: rem(1px) solid $gmb-color-disabled;
    //border-radius: rem(3px);
    padding-left: rem(10px);
    padding-right: rem(10px);
    display: table;
    padding: 0;
    &.active {
      border: rem(1px) solid $gmb-color-default;
      }
    .icon-container {
      @include gmb-text-body;
      color: $gmb-color-default;
      cursor: pointer;
      border: 0;
      a, a:hover, a:focus {
        color: $gmb-color-default;
      }
    }

  }
  //parte relativa al input che apre il calendar
  .date-filter {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    min-width: rem(107px);
    white-space: nowrap;
    padding: 0 rem(10px);
    label {
      margin: 0;
      margin-right: 2px;
      @include gmb-text-body;
      color: $gmb-color-default;
      vertical-align: middle
    }
    .button-close {
      border: 0;
      padding-right: rem(10px);
      @include gmb-text-body;
      color: $gmb-color-default;
      vertical-align: middle
    }
    span {
      color: $gmb-color-negative;
      border: 0;
      @include gmb-text-body;
      color: $gmb-color-default;
      margin-right: rem(10px);
      vertical-align: middle
    }
    .calendarStyleLabel {
      color: $gmb-color-default;
    }
    .calendarStyleValue {
      color: $gmb-color-default;
    }
  }
  //parte relativa all'icona che apre il calendar
  .icon-container {
    width: rem(41px);
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    padding: 0 rem(10px);
    height: rem(38px);
    &.active {
      //background-color: #00afd0;
      .calendar-icon {
        color: $gmb-color-accent;
        //color: #fff !important
      }
      > a {
        color: $gmb-color-accent !important;
        //color: #fff !important
      }
    }
    &.padded-top{
      padding: rem(4px) rem(10px) 0;
    }
  }
  // input dal al data
  .filter-date-container {
    position: relative;
    border-bottom: 0;
    display: flex;
    text-align: left;
    padding: rem(20px) rem(20px) 0;
    &:after {
      content: "";
      display: table;
      clear: both
    }
  }
  // contenitore input dal al data
  .filter-date {
    width: calc(50% - 10px);
    display: inline-block;
    background: white;
    border-bottom: 0;
    border-top: 0;
    position: relative;
    margin-left: rem(10px);
    &:nth-of-type(1)  {
      margin-left: 0;
      margin-right: rem(10px);
    }
    br {
      display: none
    }
  }
  //input dal al data
  .input-date {
    background-color: #FFF;
    @include gmb-text-inputTxt;
    width: 100%;
    color: $gmb-color-default;
    padding: rem(7px) rem(10px);
    height: auto;
    border: rem(1px) solid $gmb-color-disabled;
  }
  //label input dal al data
  .label-date {
    @include gmb-text-inputTxt;
    display: block;
    text-transform: capitalize;
    margin-bottom: rem(4px);
    color: $gmb-color-secondary;
  }
  //bottoni trimistali dentro calendario
  .button-time-search {
      line-height: rem(28px);
      height: rem(30px);
      text-transform: uppercase;
      font-family: 'unicredit-medium';
      font-size: rem(14px);
      border: rem(1px) solid #ccc;
      background-color: $gmb-color-k7;
      color: $gmb-color-secondary;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      white-space: nowrap;
      float: left;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 3px;
      width: calc(33% + 1px);
      &.active {
        border: rem(1px) solid $gmb-color-accent;
        background-color: $gmb-color-accent;
        color: #fff;
      }
      &:nth-child(2) {
        width: 34%
      }
      &.button-time-search-last:after {
        display: none
      }
  }
  // bottoni trimistali dentro calendario
  .time-container {
    position: relative;
    color: #666;
    font-family: "unicredit-medium";
    font-size: 1.6rem;
    height: 63px;
    text-align: center;
    padding: 18px 20px;
    text-transform: uppercase;
    display: block;
    &:after  {
      content: "";
      display: table;
      clear: both
    }
    > {
      .button-time-search {
        +.button-time-search {
          margin-left: -1px
        }
        &:first-child:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0
        }
        &:not(:first-child):not(:last-child) {
          border-radius: 0
        }
      }
    }
  }
  // parte bottom del calendario con bottoni
  .submit-calendar {
    position: relative;
    border: 0;
    padding: 15px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-time-search {
        background: none;
        cursor: default;
        border: 0;
        text-align: left;
        width: auto;
        height: auto;
        color: $gmb-color-accent;
        cursor: pointer;
        @include gmb-text-label-upper;
        &::after {
          right: 0;
          width: 0
        }
        .ui-button-text {
            cursor: pointer;
            font-size: 14px;
            color: $gmb-color-accent;
            font-family: 'unicredit-bold';
            padding: 0;
        }
    }
    .btn-activated  {
      border: rem(1px) solid $gmb-color-accent;
      background-color: $gmb-color-accent;
      color: #fff;
      border-radius: rem(3px);
      display: inline-block;
      text-transform: none !important;
      font-size: rem(18px);
      line-height: rem(22px);
      padding: rem(14px) rem(20px);
      font-family: 'unicredit-bold';
      height: rem(50px);
    }
    >a , >button {
      display: inline-block
    }
  }
  // container relativo solo alle date del mese
  .ui-datepicker-calendar  {
    thead {
      display: table-header-group !important;
      th span {
        color: $gmb-color-secondary;
        text-transform: uppercase;
        @include gmb-text-label-lowercase;
      }

    }
  }
  .calendar-button .ui-button-text {
    padding: 0;
    display: none
  }
  .filter-date-text {
    display: none
  }
  .container-ico-minus-calendar {
    display: none
  }
  .ui-datepicker-calendar td:not(.selected):not(.ui-datepicker-today)>a,.calendar-full-version .ui-datepicker td.ui-state-disabled>span {
    background-color: transparent
  }


}


.container-accesslog .calendar-full-version .calendar-button-container {
  float: right
}

.at-container-accesslog .calendar-full-version .calendar-button-container {
  position: absolute;
  right: 20px;
  top: -77px
}
.at-container-accesslog .calendar-full-version .calendar_container {
  top: -37px;
  right: 20px
}


.ui-datepicker.ui-shadow {
  box-shadow: none;
  border-radius: 0;
}

.ui-datepicker {
  z-index: 2040 !important;
  padding: rem(20px);
  border-radius: 0;
  background-color: #fff;
  border: rem(1px) solid #ccc;
  display: table-row-group;
  min-width: rem(290px);
  .ui-datepicker-title {
    font-family: "unicredit-bold";
    color: $gmb-color-default;
    @include gmb-text-paragraph-uppercase;
    //font-size: rem(30px);
    //line-height: rem(42px);
    margin: 0 auto 20px;
  }
  .ui-datepicker-header {
    height: auto;
    padding: 0;
    border-radius: 0;
    width: 100%;
    position: relative;
    a {
      &.ui-datepicker-prev {
          position: absolute;
          top: rem(2px);
          left: rem(30px);
          text-align: center;
          line-height: rem(20px);
          width: rem(20px);
          height: rem(20px);
          cursor: pointer;
          &.ui-state-disabled {
            visibility: hidden
          }
          &:before {
            content: '\e957';
            font-family: 'UC-Official-icon-set' !important;
            font-size: rem(14px);
            color: $gmb-color-default;
          }
        }
       &.ui-datepicker-prevYear {
          position: absolute;
          top: rem(2px);
          left: 0;
          text-align: center;
          line-height: rem(20px);
          width: rem(20px);
          height: rem(20px);
          cursor: pointer;
          &.ui-state-disabled {
            visibility: hidden
          }
          &:before {
            content: '\e957 \e957';
            font-family: 'UC-Official-icon-set' !important;
            font-size: rem(14px);
            color: $gmb-color-default;
          }
        }
      &.ui-datepicker-next {
          position: absolute;
          top: rem(2px);
          right: rem(30px);
          text-align: center;
          line-height: rem(20px);
          width: rem(20px);
          height: rem(20px);
          cursor: pointer;
          &.ui-state-disabled {
            visibility: hidden
          }
          &:before {
            content: '\e95a';
            font-family: 'UC-Official-icon-set' !important;
            font-size: rem(14px);
            color: $gmb-color-default;
          }
        }
        &.ui-datepicker-nextYear {
          position: absolute;
          top: rem(2px);
          right: 0;
          text-align: center;
          line-height: rem(20px);
          width: rem(20px);
          height: rem(20px);
          cursor: pointer;
          &.ui-state-disabled {
            visibility: hidden
          }
          &:before {
            content: '\e95a \e95a';
            font-family: 'UC-Official-icon-set' !important;
            font-size: rem(14px);
            color: $gmb-color-default;
          }
        }
    }
  }
  table.ui-datepicker-calendar {
    td {
      &.ui-state-disabled>span,
      &.ui-datepicker-unselectable.ui-state-disabled>span {
        color: $gmb-color-disabled;
        width: rem(34px);
        height: rem(34px);
        line-height: rem(34px);
        @include gmb-text-bold;
        font-size: rem(14px);
        vertical-align: middle;
        text-align: center;
      }
      &.ui-datepicker-today:not(.selected)>a {
        background-color: transparent;
        color: $gmb-color-default;
        border-radius: 50%;
        border: rem(1px) solid black;
      }
      &.ui-datepicker-current-day:not(.selected):not(.ui-datepicker-today)>a.ui-state-default {
        color: $gmb-color-default;
        border-radius: 100%
      }
      &.ui-datepicker-current-day>a.ui-state-default {
        //color: #FFF;
        border-radius: 100%
      }
      &:hover:not(.selected):not(.ui-datepicker-current-day)>a {
        //background-color: $gmb-color-k7;
        border-radius: 50%
      }
      > a,
      > span {
        display: block;
        padding: 0;
        text-align: center;
        text-decoration: none;
        font-size: rem(14px);
        color: $gmb-color-default;
        font-family: "unicredit-regular";
        width: rem(34px);
        height: rem(34px);
        line-height: rem(34px);
        margin: 0 auto
      }

    }
    th {
      padding: 0 0 rem(10px);
      margin: 0;
      span {
        font-size: rem(14px);
        color: $gmb-color-secondary;
        font-family: "unicredit-bold" !important;
        padding: 0;
        text-transform: uppercase;
      }
    }
   }
}


.ui-datepicker-trigger {
  position: absolute;
  height: rem(20px);
  width: rem(20px);
  border: 0 none;
  line-height: rem(20px);
  margin: rem(10px) 0 0 0;
  right: rem(10px);
  border-radius: 0 !important;
  &::before {
    content: "\e969";
    font-family: 'UC-Official-icon-set' !important;
    color: $gmb-color-default;
    font-size: rem(18px);
  }
}

.ui-datepicker-trigger .ui-button-text {
    display: none
}

.calendar-navigator {
  .ui-datepicker-prev,
  .ui-datepicker-next,
  .ui-datepicker-prevYear,
  .ui-datepicker-nextYear {
    display: none;
  }
  select.ui-datepicker-month,
  select.ui-datepicker-year {
    background: url('/etc/designs/gimb2-it/img/navigation-and-action-arrow-down.png') no-repeat;
    background-position: bottom 50% right 12px;
    background-size: 12px;
    padding-left: rem(10px);
    &:first-child {
      margin-right: rem(7.5px);
    }
  }
  select.ui-datepicker-month,
  select.ui-datepicker-year,
  .chosen-container {
    direction: ltr;
    margin-top: rem(1px);
    margin-bottom: rem(1px);
    text-transform: capitalize;
    width: calc(50% - 7.5px) !important;
    &:last-child {
      margin-left: rem(7.5px);
    }
  }
  .chosen-container {
    span {
      margin-left: rem(10px);
      text-transform: capitalize;
    }
    div b {
      display: none;
    }
    &:nth-child(2) {
      margin-right: rem(7.5px);
    }
  }
  .chosen-single {
    &::after {
      background-image: url('/etc/designs/gimb2-it/img/navigation-and-action-arrow-down.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      color: transparent;
      content: "."; // "\e958";
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
    }
  }
  .chosen-with-drop {
    & .chosen-single {
      &::after {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }
  .ui-datepicker-title .chosen-container .chosen-drop .chosen-results .active-result {
    padding-left: rem(10px);
    text-align: left;
    text-transform: capitalize;
  }
}
