$dropdown-text-height: 40px;

%gmbDropdownItemTemplate {
  padding: rem(10px);
  height: $dropdown-text-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

gmb-dropdown-checkable {
  @include inline-block-element(true);

  .gmb-dropdown-container {
    @include inline-block-element(true);

    .gmb-dropdown-label  {
      @include gmb-text-inputLabel;
      color: $gmb-color-default;
      margin-bottom: rem(4px);
    }

    .gmb-dropdown-placeholder-wrapper {
      @extend %gmbDropdownItemTemplate;
      @include gmb-text-inputTxt;
      color: $gmb-color-secondary;
      border: 1px solid $gmb-color-disabled;
      background-color: white;

      &:hover {
        color: $gmb-color-default;
        border-color: $gmb-color-default
      }

      &.gmb-dropdown-error {
        border: 2px solid $gmb-color-negative;
      }

      &.show-dropdown-content {
        color: $gmb-color-default;
        border: 1px solid $gmb-color-default;
      }

      .gmb-dropdown-placeholder {
        @include inline-block-element;
        vertical-align: middle;
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .gmb-dropdown-infos {
        float: right;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        background-color: white;
        outline: none;

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .gmb-arrow {
          @include inline-block-element;
          width: rem(14px);
          height: rem(14px);
          line-height: rem(14px);
          position: relative;
          text-align: center;
          vertical-align: middle;
          font-size: rem(12px);
        }
        .gmb-arrow:before {
          position: relative;
          width: rem(14px);
          height: rem(14px);
          display: inline-block;
          line-height:rem(14px);
          color: $gmb-color-default;
        }
        .gmb-dropdown-active-info {
          @include inline-block-element;
          @include gmb-text-label-upper;
          margin-right: rem(8px);
          color: $gmb-color-default;
        }
      }

      &.show-dropdown-content .gmb-arrow:before {
        transform: rotate(180deg);
      }
    }

    .gmb-dropdown-content {
      display: none;
      border: 1px solid $gmb-color-default;
      border-top: none;
      background-color: white;
      position: absolute;
      z-index: 2;
      box-sizing: border-box;

      gmb-checkbox {
        @include inline-block-element(true);
        .gmb-checkbox-container {
          @include inline-block-element(true);
          @include gmb-text-body;
          padding: rem(10px);
          box-sizing: border-box;
          height: $dropdown-text-height;

          .gmb-checkbox-wrapper {
            float: right;
          }

          &:hover {
            @extend %gmb-color-bg-blue;
          }
        }

        & + gmb-checkbox {
          margin-top: 0;
        }
      }

      &.show-dropdown-content {
        @include inline-block-element(true);
      }
    }

    .gmb-dropdown-error-wrapper {
      @extend %gmbDropdownItemTemplate;
      background-color: $gmb-color-negative;
      box-sizing: border-box;
      color: white;
      position: absolute;
      z-index: 1;
    }


    &.gmb-dropdown-disabled {
      cursor: default;
      color: $gmb-color-disabled;
      pointer-events: none;

      .gmb-dropdown-placeholder-wrapper {
        border: 1px solid $gmb-color-disabled;
        color: $gmb-color-disabled;
        background-color: $gmb-color-k7;

        .gmb-dropdown-infos {
          background-color: $gmb-color-k7;
          .gmb-dropdown-active-info {
            color: $gmb-color-disabled;
          }
          .gmb-arrow:after {
            border-color: $gmb-color-disabled;
          }
        }
      }
    }
  }
}
