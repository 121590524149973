.gmb-message {
	@extend %gmb-color-bg-blue;
	@include gmb-text-body;
	padding: rem(20px);
  border-radius:rem(4px);
  .link-access {
    color: $gmb-color-white;
    &:hover, &:focus {
      color: $gmb-color-white;
    }
  }

  &-error{
    background-color: $gmb-color-negative;
  }
}

.validation_style_pmessage {

  .ui-messages-error-detail {
    @include gmb-text-subtitle;
  }

}

.validation_style_priority_message {
  .ui-messages-error{
    display: block;
    text-align: center;
    font-size: 1.6rem;
    background-color: #d73928;
    color: #fff;
    max-width: 380px;
    padding: 0;
    margin: 4px auto;
    margin-bottom: 20px;
    border: 0;
    overflow-x: hidden;
    word-break: normal;
    white-space: normal
  }

  .ui-messages-error-summary {
    @include gmb-text-subtitle;
  }

  .ui-messages-error-detail {
    @include gmb-text-info;
  }

  span.UXUI-icon{
    margin-right: rem(10px);
    margin-top: rem(4px);
    vertical-align: top;
    display: inline-block;
  }

  span.error-section{
    display: inline-block;
    white-space: initial;
    max-width: rem(302px);
  }

  .errors li {
    padding: rem(20px);
    text-align: left;
    min-width: rem(360px);
    max-width: rem(500px);
    margin: 0 auto;
    color: $gmb-color-white;
    border-radius: rem(4px);
    white-space: nowrap;

    &.error {
      background-color: $gmb-color-negative;
    }
    &.info {
      background-color: $gmb-color-accent;
    }
  }

  .errors ul{
    margin: 0;
    padding: 0;
  }

}


