%gmb-button-template {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: rem(11px) rem(20px);
  border-radius:rem(4px);
  outline: none;
  min-width: 120px;
  @include gmb-text-button;
}

/* GIMB BUTTON  */

gmb-button {
  @include inline-block-element;

  > input:hover {
    cursor: pointer;
  }

  .classic {
    @extend %gmb-button-template;
    @extend %gmb-color-bg-gray;
    border: 2px solid transparent;
    padding: rem(4px) rem(12px)!important;

    &:hover{
      background-color: #E8E8E8;
      border: 2px solid #E5E5E5;
    }
    &:active{
      color:#026275;
      background-color:#ffffff;
      border: 2px solid $gmb-color-accent;
    }

  }

  .primary {
    @extend %gmb-button-template;
    @include gmb-cta-primary;
  }

  .secondary {
    @extend %gmb-button-template;
    @include gmb-cta-secondary;
  }

  .secondary-negative {
    @extend %gmb-button-template;
    @include gmb-cta-secondary-negative;
  }

  .gmb-font-size-small {
    font-size: rem(12px);
  }

  .gmb-btn-disabled {
    @extend %gmb-button-template;
    @include gmb-cta-disabled;
  }

  + gmb-button {
    margin-left: rem(40px);
  }
}

.button-fac-simile {
  cursor: pointer;

  &.classic {
    @extend %gmb-button-template;
    @extend %gmb-color-bg-gray;
    border: 2px solid transparent;
    padding: rem(4px) rem(12px)!important;

    &:hover{
      background-color: #E8E8E8;
      border: 2px solid #E5E5E5;
    }
    &:active{
      color:#026275;
      background-color:#ffffff;
      border: 2px solid $gmb-color-accent;
    }

  }

  &.primary {
    @extend %gmb-button-template;
    @include gmb-cta-primary;
  }

  &.secondary {
    @extend %gmb-button-template;
    @include gmb-cta-secondary;
  }

  &.secondary-negative {
    @extend %gmb-button-template;
    @include gmb-cta-secondary-negative;
  }

  &.gmb-btn-disabled {
    @extend %gmb-button-template;
    @include gmb-cta-disabled;
  }

  &.ui-button {

    .ui-button-text {
      padding: 0 !important;
      line-height: rem(24px) !important;
    }
  }


}
