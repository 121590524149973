$gmb-checkbox-height: rem(22px);
$gmb-checkbox-width: rem(22px);

gmb-checkbox {

  @include inline-block-element;

  /* The container */
  .gmb-checkbox-container {
    display: flex;

    &.gmb-checkbox-container-clickable {
      cursor: pointer;
    }

    .gmb-checkbox-wrapper {
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      text-align: center;
      margin-right: rem(11px);

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        display: none;
      }

      .gmb-checkmark {
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        top: 0;
        left: 0;
        line-height: $gmb-checkbox-height;
        height: $gmb-checkbox-height;
        width: $gmb-checkbox-width;
        background-color: white;
        border: solid 1px $gmb-color-default;
        border-radius: 4px;
        outline: none;
        font-size: rem(14px);
      }

      /* On mouse-over, add a background color */
      &:hover input ~ .gmb-checkmark, &:focus input ~ .gmb-checkmark {
        border: solid 1px $gmb-color-secondary;
        background-color: $gmb-color-k7;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .gmb-checkmark:before {
        opacity: 0;
        color: $gmb-color-accent;
      }

      /* Show the indicator (dot/circle) when checked */
      input:checked ~ .gmb-checkmark:before {
        opacity: 1;
      }

      input[disabled] ~ .gmb-checkmark {
        cursor: default;
        pointer-events: none;
        border: solid 1px $gmb-color-disabled;
        background-color: $gmb-color-k7;
      }

      input:not(:checked) ~ .gmb-checkbox-error {
        border: solid 2px $gmb-color-negative;
      }
    }

    .gmb-checkbox-label-wrapper {
      display: inline-block;
      vertical-align: top;

      &.gmb-checkbox-label-disabled {
        cursor: default;
        color: $gmb-color-disabled;
        pointer-events: none;
      }

      .label-checkbox  {
        @include gmb-text-body;
      }
    }
  }

  .gmb-checkbox-error-container {
    background-color: $gmb-color-negative;
    box-sizing: border-box;
    color: white;
    padding: rem(9px) rem(10px);
    margin-top: rem(7px);
    width: 100%;
    @include gmb-text-body;
    text-align: left;
  }
  + gmb-checkbox {
    margin-top: rem(25px);
  }
}
