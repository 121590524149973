$input-text-min-width: rem(200px);
$input-text-max-width: rem(360px);
$input-text-height: rem(40px);
$input-text-arrow-height: rem(10px);

%gmbInputContainerTemplate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $input-text-height;
  width: 100%;
  padding: rem(9px) rem(10px) rem(7px) rem(10px);
  // max-width: $input-text-max-width;
  // min-width: $input-text-min-width;
  border: 1px solid $gmb-color-disabled;
  border-radius: 0;
  background: white;
  box-sizing: border-box;

  &:hover {
    border-color: $gmb-color-default;
  }
}
%gmbInputContainerTemplate_simple{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $input-text-height;
  width: 100%;
  padding: rem(10px);
  // max-width: $input-text-max-width;
  // min-width: $input-text-min-width;
  border: 1px solid $gmb-color-disabled;
  border-radius: 0;
  background: white;
  box-sizing: border-box;
}


%gmbInputTextTemplate {
  @include gmb-text-inputTxt;
  border: none;
  width: 100%;
  padding: 0;

  &:focus, &:hover {
    outline: none;
  }
}

%gmbInputContainerTemplate.has-focus {
  border-color: $gmb-color-default;
}

%gmbInputContainerTemplate.error-message {
  //background-color: rgb(255,235,234);
  border-color: $gmb-color-negative !important;
  border-width: 2px;
}

%gmbInputContainerTemplate.info-message {
  border-color: $gmb-color-positive;
}

%gmbInputTextTemplate.warning-message {
  border-color: $gmb-color-warning;
}

%gmbInputIconTemplate {
  margin-left: rem(5px);
  display: flex;
}
