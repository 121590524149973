//utility general layout
@import '../comps/button.scss';

$spaces: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 100, 150);


@each $space in $spaces {
  //padding left right
  .gmb-column-padding-lr-#{$space} {
    padding-left: remSpacer($space);
    padding-right: remSpacer($space);
  }
  //padding top e bottom
  .gmb-column-padding-tb-#{$space} {
    padding-top: remSpacer($space);
    padding-bottom: remSpacer($space);
  }
  //padding only right padding 0 altro lato
  .gmb-column-padding-right-#{$space} {
    padding-right: remSpacer($space);
    padding-left: 0;
  }
  //padding only left  padding 0 altro lato
  .gmb-column-padding-left-#{$space} {
    padding-left: remSpacer($space);
    padding-right: 0;
  }
    //padding only right
    .gmb-column-padding-only-right-#{$space} {
      padding-right: remSpacer($space);
    }
    //padding only left
    .gmb-column-padding-only-left-#{$space} {
      padding-left: remSpacer($space);
    }

  //padding only top
  .gmb-column-padding-top-#{$space} {
    padding-top: remSpacer($space);
  }
  //padding only bottom
  .gmb-column-padding-bottom-#{$space} {
    padding-bottom: remSpacer($space);
  }
  //margin sinistra e destra
  .gmb-column-margin-lr-#{$space} {
    margin-right: remSpacer($space);
    margin-left: remSpacer($space);
  }
  //margin only right
  .gmb-column-margin-right-#{$space} {
    margin-right: remSpacer($space);
  }
  //margin only left
  .gmb-column-margin-left-#{$space} {
    margin-left: remSpacer($space);
  }
  //margin only bottom
  .gmb-column-margin-bottom-#{$space} {
    margin-bottom: remSpacer($space);
  }
  //margin only top
  .gmb-column-margin-top-#{$space} {
    margin-top: remSpacer($space);
  }
  //margin top e bottom
  .gmb-column-margin-tb-#{$space} {
    margin-top: remSpacer($space);
    margin-bottom: remSpacer($space);
  }
}

//utility for float
.float-right{
  float: right;
}

.float-left{
  float: left;
}

//utility for feedback class
.feedback__container {
  margin: rem(45px) auto rem(20px);
  text-align: center;
  max-width: rem(650px);
  &.feedback__container--left {
    text-align: left;
    margin: 0;
  }
}

.feedback__container--top10-bottom40 {
  margin: rem(10px) auto rem(40px);
  text-align: center;
  max-width: rem(650px);
  &.feedback__container--left {
    text-align: left;
    margin: 0;
  }
}

.feedback__icon {
  font-size: rem(130px);
  color: $gmb-color-default;
  margin-bottom: rem(40px);
  display: block;
  .feedback__container--small &,
  .feedback__container--left & {
    font-size: rem(48px);
    margin-bottom: rem(20px);
  }
  &.feedback__icon--success {
    color: $gmb-color-positive;
  }
  &.feedback__icon--warning {
    color: $gmb-color-warning;
  }
  &.feedback__icon--error {
    color: $gmb-color-negative;
  }

}
.feedback__title {
  @include gmb-text-headline;
  margin-bottom: rem(18px);
  margin-top: 0;
  .feedback__container--left &,
  .feedback__container--small & {
    @include gmb-text-subtitle;
    margin-bottom: rem(5px);
  }
}
.feedback__par {
  @include gmb-text-body;
  margin-bottom: rem(20px);
}

.feedback__par_margin40 {
  @include gmb-text-body;
  margin-bottom: rem(40px);
}

.thanks-content .feedback__container br + br {
  display: none;
}

//utility for align text
.align__text--center {
  text-align: center;
}
.align__text--left {
  text-align: left;
}
.align__text--right {
  text-align: right;
}
.align__text--centet {
  /* deprecated: Use .align__text--center instead. */
  text-align: center;
}


//utility for row column wiht last element margin auto
.row__flex {
  display: flex;
  align-items: center;
  min-height: rem(24px);
  > *:last-child {
    margin-left: auto;
  }
  .row__flex--right {
    text-align: right;
  }
  &.row__flex--top {
    align-items: flex-start;

    .gmb-typ-label-upper {
      line-height: 24px;
    }
  }
  &.row__flex--bottom {
    align-items: flex-end;
  }
}


//utility for button container
.button__container--center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button__container--fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


//banner con il bottone

.bannerButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: rem(4px);
  border: rem(2px) solid $gmb-color-default;
  padding: rem(20px) rem(20px) rem(20px) rem(20px);

  gmb-button, .button-fac-simile {
    margin-right: rem(20px);
  }
}

.bannerButton__left {
  display: flex;
  align-items: flex-start;
  padding-right: rem(21px);
}
.bannerButton__icon {
  margin-right: rem(18px);
  font-size: rem(22px);
}
.bannerButton__title {
  margin-bottom: 7px;
  margin-top: 0;
  @include gmb-text-paragraph-uppercase;
}

.bannerButton__paragraph {
  @include gmb-text-body;
  margin: 0;
}

.bannerButton__buttonIcon {
  font-size: rem(10px);
}

.gmb-h34-border-bottom {
  line-height: rem(34px);
  border-bottom: 1px solid $gmb-color-k6;
}

.gmb-separator-line {
  border-bottom: 1px solid $gmb-color-disabled;
}

.bannerButton.bannerButton__Highlihgt {
  border: none;
  background-color: $gmb-color-accent;
  color: $gmb-color-white;
}

.bannerButton__buttonHighlight{
  @extend %gmb-button-template;
  @include gmb-cta-secondary-negative;
}

.bulletPoint {
  li {
    @include gmb-text-body;
    padding-left: rem(28px);
    position: relative;

    &:before {
      content: "";
      width: rem(8px);
      height: rem(8px);
      border-radius: 50%;
      background-color: $gmb-color-default;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: rem(10px);
      top: 0.5em;
      .gmb-color-white &{
        background-color: $gmb-color-white;
      }
    }
  }
}

.first-letter-upper::first-letter {
  text-transform: uppercase;
}
