gmb-icon-button[disabled=true]{
  .icon-button{
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }
}

gmb-icon-button{
  @include inline-block-element;
  background-color: inherit;

  .icon-button{
    background-color: inherit;
    border: none;
    box-sizing: border-box;
    width: 100%;
    color: $gmb-color-default;
    //padding: rem(16px) rem(6px);
    display: block;

    .icon-span{
      background-color: inherit;
      width: 100%;
      font-size: rem(22px);
      position: relative;
      display: block;
      margin-bottom: rem(5px);
      text-align:center;
      height: rem(24px);
      width: rem(24px);
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      &.icon-span--lg{
        font-size: rem(48px);
        line-height: rem(48px);
        height: rem(50px);
        width: rem(50px);
      }
      &.icon-span--xl{
        font-size: rem(90px);
        line-height: rem(92px);
        height: rem(92px);
        width: rem(92px);
      }
    }

    .image-png{
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 26px;
    }

    .label-span{
      background-color: inherit;
      @include gmb-text-label-upper;
      text-align: center;
      width: 100%;
    }

    &:link, &:hover, &:active, &:visited {
      text-decoration: none;
    }

    &.accent { color: $gmb-color-accent; }
    &.secondary { color: $gmb-color-secondary; }
    &.positive { color: $gmb-color-positive; }
    &.warning { color: $gmb-color-warning; }
    &.negative { color: $gmb-color-negative; }
    &.visited { color: $gmb-color-link-visited; }
  }

  .change-color {

    &:hover{
      color: $gmb-color-secondary;
    }

    &.accent {
      color: $gmb-color-accent;
    }
  }

  .no-change-color{
    border-bottom: solid 0.3em transparent;
    transition: all 0.3s;

    &.active-underlined{
      border-bottom-color: inherit;
    }

    &:hover,&:active,&:focus{
      border-bottom-color: inherit;
    }

  }

  .change-color {
    &.icon-button-visited {
      color: $gmb-color-link-visited;
    }
  }

  .white{
    color: $gmb-color-white;

    &:hover {
      color: $gmb-color-white;
    }
  }

  .export-color{
    color: $gmb-color-accent;
  }

}
