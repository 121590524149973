@import './placeholders/dropdown.scss';


gmb-dropdown {
  @include inline-block-element(true);

  .gmb-dropdown-container {
    @include inline-block-element(true);

    .gmb-dropdown-label  {
      @extend %gmbDropdownLabelTemplate;
    }

    .gmb-dropdown-placeholder-wrapper {
      @extend %gmbDropdownWrapperTemplate;
    }

    .gmb-dropdown-content{
      @extend %gmbDropdownContentTemplate;
    }

    .gmb-dropdown-error-wrapper {
      @extend %gmbDropdownItemTemplate;
      background-color: $gmb-color-negative;
      box-sizing: border-box;
      color: white;
      position: absolute;
      z-index: 1;
    }


    &.gmb-dropdown-disabled {
      cursor: default;
      color: $gmb-color-disabled;
      pointer-events: none;

      .gmb-dropdown-placeholder-wrapper {
        border: 1px solid $gmb-color-disabled;
        color: $gmb-color-disabled;
        background-color: $gmb-color-k7;

        .gmb-dropdown-infos {
          background-color: $gmb-color-k7;
          .gmb-dropdown-active-info {
            color: $gmb-color-disabled;
          }
          .gmb-arrow:after {
            border-color: $gmb-color-disabled;
          }
        }
      }
    }
  }
}
