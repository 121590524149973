gmb-infotip{
  @include inline-block-element;
  width: rem(18px);
  height: rem(18px);
  vertical-align: middle;
  margin-left: rem(8px);

  .gmb-infotip-span{
    color: $gmb-color-default;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    &[open="true"] {
      color: $gmb-color-accent
    }
    &.bottom-direction.right-direction  {
      .gmb-infotip-text {
        top: rem(36px);
        bottom: initial;
        &:before {
          border-width: rem(20px) 0 0 rem(20px);
          top: rem(-13px);
          border-color: transparent transparent transparent #bfebf3;
          bottom: initial;
        }
      }
    }
    &.bottom-direction.left-direction  {
      .gmb-infotip-text {
        top: rem(36px);
        bottom: initial;
        right: rem(10px);
        left: initial;
        &::before {
          right: -1px;
          top: rem(-13px);
          border-color: transparent transparent #bfebf3 transparent;
          border-width: 0 0 rem(20px) rem(20px);
          bottom: initial;
          left: initial;
        }
      }
    }
    &.top-direction.left-direction  {
      .gmb-infotip-text {
        right: rem(10px);
        left: initial;
        &::before {
          right: -1px;
          border-color: transparent #bfebf3 transparent transparent;
          border-width: 0 rem(20px) rem(20px) 0;
        }
      }
    }


    &:before{
      font-size: rem(18px);
    }

    &.white:before {
      color: white;
    }

    &.accent:before {
      color: $gmb-color-accent;
    }

    &:focus{
      outline: 1px solid red;
    }

    .gmb-infotip-text[open=true] {
      display: block;
      z-index: 1001;
    }

    .gmb-infotip-text {
      display: none;
      background-color: $gmb-color-b2;
      border: 1px solid $gmb-color-b2;
      border-radius: rem(4px);
      cursor: default;
      min-width: rem(246px);
      position: absolute;
      text-align: left;
      z-index: 1;
      bottom: rem(36px);
      padding: rem(8px) (15px);
      left: 10px;
      @include gmb-text-body;
      color: $gmb-color-default;
      white-space: normal;

      &::before {
        border-left-width: 0;
        border-style: solid;
        border-width: rem(20px) rem(20px) 0 0;
        content: "";
        position: absolute;
        left: -1px;
        bottom: rem(-13px);
        border-color: #bfebf3 transparent transparent transparent;

      }

    }


  }


}

