
$size-font-base: 14px;
$size-font-baseNopx: 14;

html, body  {
  font-size: $size-font-base;
}

@function rem($size){
  @return #{$size/$size-font-base}rem;
}

@function remSpacer($size){
  @return #{$size/$size-font-baseNopx}rem;
}

@mixin gmb-text-arial{
  font-family: "arial";
}

@mixin gmb-text-regular{
  font-family: "unicredit-regular";
}

@mixin gmb-text-bold{
  font-family: 'unicredit-bold';
}

@mixin gmb-text-medium{
  font-family: 'unicredit-medium';
}

@mixin gmb-text-capitalized {
  text-transform: capitalize;
}

// gmb-text-title -> gmb-text-display
@mixin gmb-text-display{
  @include gmb-text-bold;
  line-height: rem(50px);
  font-size: rem(50px);
}

@mixin gmb-text-headline{
  @include gmb-text-bold;
  font-size: rem(35px);
  line-height: rem(42px);
}

@mixin gmb-text-headline-regular{
  @include gmb-text-regular;
  font-size: rem(35px);
  line-height: rem(42px);
}

@mixin gmb-text-importo{
  @include gmb-text-bold;
  font-size: rem(30px);
  line-height: rem(33px);
}

@mixin gmb-text-valuta{
  @include gmb-text-bold;
  font-size: rem(25px);
  line-height: rem(27px);
}

@mixin gmb-text-valuta-regular{
  @include gmb-text-regular;
  font-size: rem(25px);
  line-height: rem(27px);
}

@mixin gmb-text-valuta-regular-uppercase{
  @include gmb-text-regular;
  font-size: rem(25px);
  line-height: rem(22px);
  text-transform: uppercase;
}

//gmb-text-parTitle -> gmb-text-paragraph
@mixin gmb-text-paragraph{
  @include gmb-text-bold;
  font-size: rem(20px);
  line-height: rem(22px);
}

//gmb-text-parTitle-upper -> gmb-text-paragraph-uppercase
@mixin gmb-text-paragraph-uppercase{
  @include gmb-text-bold;
  font-size: rem(20px);
  line-height: rem(22px);
  text-transform: uppercase;
}

//gmb-text-bodyBold -> gmb-text-subtitle
@mixin gmb-text-subtitle{
  @include gmb-text-bold;
  font-size: rem(18px);
  line-height: rem(24px);
}

@mixin gmb-text-subtitle-uppercase{
  @include gmb-text-bold;
  font-size: rem(18px);
  line-height: rem(24px);
  text-transform: uppercase;
}

@mixin gmb-text-body{
  @include gmb-text-regular;
  font-size: rem(18px);
  line-height: rem(24px);
}


@mixin gmb-text-dashboard{
  @include gmb-text-bold;
  font-size: rem(16px);
  line-height: rem(17px);
  text-transform: uppercase;
}

@mixin gmb-text-info{
  @include gmb-text-regular;
  font-size: rem(16px);
  line-height: rem(17px);
}

@mixin gmb-text-label-upper{
  @include gmb-text-bold;
  font-size: rem(14px);
  line-height: rem(15px);
  text-transform: uppercase;
}

//gmb-text-small - gmb-text-label ->  gmb-text-label-lowercase
@mixin gmb-text-label-lowercase{
  @include gmb-text-bold;
  font-size: rem(14px);
  line-height: rem(15px);
}


@mixin gmb-text-dashboard-highlight{
  @include gmb-text-medium;
  font-size: rem(14px);
  line-height: rem(12px);
}

@mixin gmb-text-dashboard-text{
  @include gmb-text-regular;
  font-size: rem(14px);
  line-height: rem(12px);
}

@mixin gmb-text-dashboard-close{
  @include gmb-text-bold;
  font-size: rem(11px);
  line-height: rem(12px);
  text-transform: uppercase;
}


@mixin gmb-text-dislacimer{
  @include gmb-text-arial;
  line-height: rem(12px);
  font-size: rem(11px);
}


@mixin gmb-text-inputTxt{
  @include gmb-text-regular;
  line-height: rem(24px);
  font-size: rem(18px);
}

@mixin gmb-text-inputLabel{
  @include gmb-text-regular;
  line-height: rem(24px);
  font-size: rem(18px);
}


@mixin gmb-text-button{
  font-size: rem(18px);
  line-height: rem(24px);
  @include gmb-text-bold;
}

@mixin gmb-modal-label {
  @include gmb-text-bold;
  font-size: rem(14px);
  line-height: rem(15px);
  text-transform: uppercase;
  color: $gmb-color-modal-label;
}

@mixin gmb-modal-value {
  @include gmb-text-regular;
  font-size: rem(18px);
  line-height: rem(24px);
  color: $gmb-color-default;
}
