gmb-link-access {
  .link-access {
    color: $gmb-color-accent;
    display: inline-flex;
    align-items: center;
    @include gmb-text-label-upper;

    &.link-white{
      color: $gmb-color-disabled;
      &:hover, &:focus {
        color: $gmb-color-white;
      }
    }

    .link-access-container-error & {
      color: $gmb-color-negative;
    }

    .container-link-access-big &{
      @include gmb-text-paragraph;
      text-transform: none;
    }
    .label-iconTxt & {
      text-transform: none;
    }

    &:link, &:hover, &:active, &:visited {
      text-decoration: none;
    }

    &:hover, &:focus {
      color: $gmb-color-b7;
    }

    &.link-access-disabled {
      color: $gmb-color-disabled;
      cursor: default;
      pointer-events: none;
    }

    &.small {
      font-size: rem(14px);

      .icon-right, .icon-left {
        font-size: rem(12px);
      }
    }

    &.medium {
      font-size: rem(18px);

      .icon-right, .icon-left {
        font-size: rem(15px);
      }
    }

    &.large {
      font-size: rem(20px);

      .icon-right, .icon-left {
        font-size: rem(20px);
      }
    }

    &.back-link {
      color: $gmb-color-default;

      &:hover, &:focus {
        color: $gmb-color-secondary;
      }
    }

    .link-access-label {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }

    .icon-right, .icon-left {
      vertical-align: middle;
    }

    .icon-right {
      margin-left: rem(10px);
    }

    .icon-left {
      margin-right: rem(10px);
    }
  }
}

.gmb-link-access-label-not-clickable{
  position: relative;
  @include gmb-text-body;
  top: rem(2px);
}

div.link-access-opened{
  gmb-link-access{
    a{
      color: $gmb-color-pfm-alimentari-spesa !important;
    }
  }
}
