$dropdown-text-height: rem(40px);

%gmbDropdownItemTemplate {
  padding: rem(8px) rem(10px);
  height: auto !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

%gmbDropdownItemTemplateExtended{
    @extend %gmbDropdownItemTemplate;
    @include gmb-text-body;
    color: $gmb-color-default;
    background-color: white;
    border-radius: 0;

    &:hover {
      @extend %gmb-color-bg-blue;
    }

    &:active {
      color: $gmb-color-white;
    }

    &:hover, &:focus {
      text-decoration: none;
    }
}

%gmbDropdownLabelTemplate  {
  @include gmb-text-inputLabel;
  color: $gmb-color-default;
}

%gmbDropdownContentTemplate {
    display: none;
   // border: 1px solid $gmb-color-default;
   // border-top: none;
    background-color: white;
    position: absolute;
    z-index: 2;
    box-sizing: border-box;

    &.show-dropdown-content {
      @include inline-block-element(true);

      .gmb-dropdown-item {
       @extend %gmbDropdownItemTemplateExtended;
      }
    }
    .gmb-dropdown-options {
      display: none;
    }
}

%gmbDropdownWrapperTemplate {

  @extend %gmbDropdownItemTemplate;
  @include gmb-text-inputTxt;
  color: $gmb-color-secondary;
  border: 0;
  background-color: transparent;

  &:hover {
    color: $gmb-color-default;
    border-color: $gmb-color-default
  }

  &.gmb-dropdown-error {
    border: 2px solid $gmb-color-negative;
  }

  &.show-dropdown-content {
    color: $gmb-color-default;
    border: 1px solid $gmb-color-default;
  }

  .gmb-dropdown-placeholder {
    @include inline-block-element;
    vertical-align: middle;
    max-width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .gmb-dropdown-infos {
    float: right;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    background-color: white;
    outline: none;

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .gmb-arrow {
      @include inline-block-element;
      width: rem(14px);
      height: rem(14px);
      line-height: rem(14px);
      position: relative;
      text-align: center;
      vertical-align: middle;
      font-size: rem(12px);
    }
    .gmb-arrow:before {
      position: relative;
      width: rem(14px);
      height: rem(14px);
      display: inline-block;
      line-height:rem(14px);
      color: $gmb-color-default;
    }
    .gmb-dropdown-active-info {
      @include inline-block-element;
      @include gmb-text-label-upper;
      margin-right: rem(8px);
      color: $gmb-color-default;
    }
  }

  &.show-dropdown-content .gmb-arrow:before {
    transform: rotate(180deg);
  }
}
