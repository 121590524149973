$pay-button-height: rem(130px);

gmb-pay-button[disabled=true]{
  .pay-button{
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }
}

gmb-pay-button{
  @include inline-block-element;
  background-color: inherit;
  text-align: center;

  .pay-button{
      @include inline-block-element(true);
      color: $gmb-color-default;
      width: rem(165px);
      height: $pay-button-height;
      overflow: hidden;

      .pay-btn-img-container {
        height: rem(60px);
        box-sizing: border-box;
        border-width: rem(1px);
        border-style: solid;
        border-bottom: none;
        border-top-left-radius: rem(4px);
        border-top-right-radius: rem(4px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-span{
          background-color: inherit;
          font-size: rem(30px);
          text-align: center;
          vertical-align: middle;
          line-height: rem(59px);
        }

        .image-png{
          display: table-cell;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          min-height: 26px;
          vertical-align: middle;
        }
      }

      .pay-btn-label-container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(70px);
        box-sizing: border-box;
        border: rem(1px) solid $gmb-color-k6;
        border-top: none;
        border-bottom-left-radius: rem(4px);
        border-bottom-right-radius: rem(4px);
        width: 100%;

        .pay-btn-label {
          @include gmb-text-body;
          line-height: rem(20px) !important;
          padding: rem(15px) rem(5px);
        }
      }

      &:link, &:hover, &:active, &:visited {
        text-decoration: none;
      }
    }

}

.gmb-pay-button-full-background{
  .pay-button{
    @include inline-block-element(true);
    color: $gmb-color-white;
    width: rem(165px);
    height: $pay-button-height;
    overflow: hidden;
    background-image: url('/etc/designs/gimb2-it/functions/WP/EP1-PSA-PAYMENTS-FREQUENTSLIST/img/quick-operation-background.png');
    background-repeat: no-repeat;
    border-radius: rem(4px);
    padding: 0 rem(15px);

    .pay-btn-img-container {
      height: rem(60px);
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: left;

      .icon-span{
        background-color: inherit;
        font-size: rem(30px);
        text-align: center;
        vertical-align: middle;
        line-height: rem(59px);
      }
    }

    .pay-btn-label-container{
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(70px);
      box-sizing: border-box;
      width: 100%;

      .pay-btn-label {
        @include gmb-text-body;
        line-height: rem(20px) !important;
      }
    }

    &:link, &:hover, &:active, &:visited {
      text-decoration: none;
    }
  }
}
