
@import './placeholders/inputs.scss';


/* GIMB INPUT TEXT  */
gmb-input-text{
  @include inline-block-element;
  width: 100%;
  position: relative;
  vertical-align: top;

  .gmbInputContainer {
    @extend %gmbInputContainerTemplate;
  }

  .gmbInputTextDisabled {
    @include gmb-cta-disabled;
    border: 1px solid $gmb-color-disabled;

    .gmbInputText {
      background-color: $gmb-color-k7;
      color: $gmb-color-disabled;
      padding: 0 !important;

      &:focus, &:hover {
        background-color: $gmb-color-k7;
        border: 1px solid $gmb-color-disabled;
      }
    }
  }

  .gmbInputText {
    @extend %gmbInputTextTemplate;
    text-align: left;
    padding: 0 !important;
    color: $gmb-color-default;

    &::placeholder {
      text-align: left !important;
      @include gmb-text-body;
      color: $gmb-color-secondary;
      text-transform: none;
    }
  }

  .gmbInputIcon {
    @extend %gmbInputIconTemplate;

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }

  .gmbInputTxtMessageBoxContainer{
    //max-width: $input-text-max-width;
    //min-width: $input-text-min-width;
    //position:relative;
    width:100%;
  }


  .gmbInputTxtMessageBox{
    display: none;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    @include gmb-text-body;
    text-align: left;
    color: #FFF;
    z-index: 2;
    //min-width: 120px;
    //left: 0;
    //top: 0;
    //min-height: 50px;
    padding: rem(9px) rem(10px);


    &:after{
      border-color: transparent transparent transparent transparent;
      content: none;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-style: solid;
      border-width: 11px;
      margin-top: -$input-text-arrow-height;
      left: 10px;
      top: -$input-text-arrow-height;
    }

    &[open=true]{
      display: block;
    }

    &.error-message{
      background-color: $gmb-color-negative;
    }

    &.info-message{
      background-color: #4e964e;
    }

    &.warning-message{
      background-color: $gmb-color-warning;
    }

    &.warning-message::after {
      border-color: transparent transparent $gmb-color-warning transparent;
    }

    &.error-message::after {
      border-color: transparent transparent $gmb-color-negative transparent;
    }

    &.info-message::after {
      border-color: transparent transparent $gmb-color-positive transparent;
    }
  }

}

/* LABEL WITH INPUT TEXT */

//$gmb-label-input-label-height: 24px;

gmb-label-input-text{
  @include inline-block-element;
  width: 100%;
}

.lit-header{
  width: 100%;
  margin-bottom: rem(3px);
  //max-width: $input-text-max-width;
  //min-width: $input-text-min-width;

  .lit-label{
    @include inline-block-element;
    @include gmb-text-inputLabel;
    color: $gmb-color-default;
    vertical-align: middle;
    margin-bottom: 0;
    padding-bottom: 0 !important;

     &.lit-label--inline {
      white-space: nowrap;
    }
  }

  .lit-label-filled {
  	color: $gmb-color-secondary;
  }

  .lit-label-disabled {
  	color: $gmb-color-disabled;
  }

  &.gmbInputTextLabelDisabled .lit-label {
    color: $gmb-color-disabled;
  }
}

.gmbInputText {
  @extend %gmbInputTextTemplate;
  clear: left;
}
