gmb-amount-label{
  background-color: inherit;
  @include inline-block-element;
  width: 100%;
  color:white;

  .amount-label-header{
    @include inline-block-element;
    width: 100%;

    .amount-label-amount{
      @include inline-block-element;
      margin-top: 0px;
      margin-bottom: 0px;
      vertical-align: middle;
      color:white;

      .integer-part {
        @include gmb-text-headline;
        margin-right: rem(5px);
        .secondBalance & {
          @include gmb-text-headline-regular;
        }
      }

      .currency-symbol {
        @include inline-block-element;
        @include gmb-text-valuta;
        .secondBalance & {
          @include gmb-text-valuta-regular;
        }

      }

      .amount-label-tooltip {
        @include inline-block-element;
        // @include gmb-text-regular;
        gmb-infotip {
          vertical-align: unset;
          font-size:rem(19px);
        }
      }

      .amount-label-child {
        @include inline-block-element;
        vertical-align: text-bottom;
        line-height: rem(25px);
      }
    }
  }
  .amount-label-label {
   @include gmb-text-info;
  }
}
