.gmb-color-default {
  color: $gmb-color-default;
}

.gmb-color-bg-default {
  background-color: $gmb-color-default;
}

.gmb-color-secondary {
  color: $gmb-color-secondary;
}

.gmb-color-bg-secondary {
  background-color: $gmb-color-secondary;
}

.gmb-color-disabled {
  color: $gmb-color-disabled;
}

.gmb-color-bg-disabled {
  background-color: $gmb-color-disabled;
}

.gmb-color-accent {
  color: $gmb-color-accent;
}

.gmb-color-bg-accent {
  background-color: $gmb-color-accent;
}

.gmb-color-positive {
  color: $gmb-color-positive;
}

.gmb-color-bg-positive {
  background-color: $gmb-color-positive;
}

.gmb-color-warning {
  color: $gmb-color-warning;
}

.gmb-color-bg-warning {
  background-color: $gmb-color-warning;
}

.gmb-color-negative {
  color: $gmb-color-negative;
}

.gmb-color-bg-negative {
  background-color: $gmb-color-negative;
}

.gmb-color-k6 {
  color: $gmb-color-k6;
}

.gmb-color-bg-k6 {
  background-color: $gmb-color-k6;
}

// DEPRECATED
.gmb-color-investments-negative{
  color: $gmb-color-negative;
}

.gmb-color-investments-positive{
  color: $gmb-color-investments-positive;
}


.gmb-color-white {
  color: $gmb-color-white;
}

.gmb-color-bg-white {
  background-color: $gmb-color-white;
}

.gmb-color-bg-main {
  color: $gmb-color-default;
  background-color: white;
}

.gmb-color-bg-gray {
  color: $gmb-color-default;
  background-color: $gmb-color-k7;
}

.gmb-color-bg-black {
  color: white;
  background-color: $gmb-color-default;
}

.gmb-color-bg-blue {
  color: white;
  background-color: $gmb-color-accent;
}
