@import url("/etc/designs/gimb2-it/css/font-awesome.css");

// Global variables
@import './globals/index.scss';

// Templates classes
@import './templates/index.scss';

// Components
@import './comps/inputs.scss';
@import './comps/infotip.scss';
@import './comps/stepCounter.scss';
@import './comps/onOffSwitch.scss';
@import './comps/jsfMessage.scss';
@import './comps/button.scss';
@import './comps/linkAccess.scss';
@import './comps/iconButton.scss';
@import './comps/modal.scss';
@import './comps/tabButton.scss';
@import './comps/amountLabel.scss';
@import './comps/radioButton.scss';
@import './comps/chipButton.scss';
@import './comps/checkbox.scss';
@import './comps/dropdown.scss';
@import './comps/dropdownCheckable.scss';
@import './comps/payButton.scss';
@import './comps/sliderCounter.scss';
@import './comps/calendar.scss';
@import './comps/againPayment.scss';
@import './comps/modalTable.scss';
@import './comps/message.scss';
@import './comps/transactionsListCounter.scss';
