gmb-onoff-switch[disabled=true]{
  opacity: 0.3;
}

gmb-onoff-switch{
  //@include inline-block-element;

  .gmbOnOffSwitchBlock{
    @include inline-block-element;
    position: relative;
    vertical-align: middle;
  }

  .gmbOnOffSwitch{
    position: absolute;
    opacity: 0;
  }

  .gmbOnOffSwitchOuter {
    height: rem(30px);
    width:  rem(60px);
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid $gmb-color-default;
    border-radius: rem(27px);
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
    &.gmbOnOffSwitchOuterHover {
      border-color: $gmb-color-secondary;
    }
  }

  .gmbOnOffSwitchButton {
    width: rem(22px);
    height: rem(22px);
    margin: rem(2px) rem(4px);
    left:rem(1px);
    background: $gmb-color-secondary;
    border-radius: rem(12px);
    position: absolute;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
    &:hover {
      border-color: $gmb-color-secondary;
    }
  }

  .gmbOnOffSwitch:checked+.gmbOnOffSwitchOuter .gmbOnOffSwitchButton {
    left: rem(29px);
    @extend %gmb-color-bg-blue;
  }

  .gmbOnOffSwitch:checked+.gmbOnOffSwitchOuter {
    border-color: $gmb-color-accent;
  }

  .gmbOnOffSwitch:checked+.gmbOnOffSwitchOuter:hover {
    border-color: $gmb-color-b7;
  }

  .gmbOnOffSwitchLabel {
    @include gmb-text-body;
    margin-right: rem(10px);
    color: $gmb-color-default;
    vertical-align: middle;
  }

  + gmb-onoff-switch  {
    margin-top: rem(25px);
   }

}
