%gmb-chip-button-template {
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: rem(7px) rem(18px);
  border-radius:rem(4px);
  cursor: pointer;
  outline: none;
}

gmb-chip-button {
  @include inline-block-element;

  .gmb-chip-button {
    @include inline-block-element;
    @extend %gmb-color-bg-gray;
    @extend %gmb-chip-button-template;
    border-radius: 4px;
    border: solid 2px $gmb-color-k7;
    outline: none;
    @include gmb-text-label-upper;

    &:hover, &:focus {
      color: $gmb-color-secondary;
    }

    &.gmb-chip-button-active {
      background-color: transparent;
      border-color: $gmb-color-accent;
      color: $gmb-color-accent;
    }

    &[disabled=disabled] {
      cursor: default;
      pointer-events: none;
      color: $gmb-color-disabled;
    }
  }
  +gmb-chip-button {
    margin-left: rem(15px);
  }
}

.fakeChipButton {

  button {
    display: inline-block;
    border-radius: 4px;
    border: solid 2px #f5f5f5;
    outline: none;
    font-family: "unicredit-bold";
    font-size: 1rem;
    line-height: 1.0714285714rem;
    text-transform: uppercase;
    background-color: #f5f5f5;

    &.selected {
      background-color: transparent;
      border-color: $gmb-color-accent;
    }

    span {
      padding: rem(10px) rem(20px) !important;
    }
  }
}
