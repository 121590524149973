$slider-cursor-size: 24px;

.sliderCounter{
  margin: rem(17px) rem($slider-cursor-size/2) rem(40px) rem($slider-cursor-size/2);

  .sliderCounter__buttons {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(28px);
  }

  .sliderCounter__icon {
    width: rem(20px);
    height: rem(20px);
    line-height: rem(20px);
    display: inline-block;

    a{
      line-height: rem(20px);
      width: rem(20px);
      height: rem(20px);
      font-size: rem(20px);
      color: $gmb-color-default;
      cursor: pointer;
    }

    a:hover{
      text-decoration: none;
    }
    a:focus{
      outline: none;
    }

    gmb-icon-button .icon-button .icon-span{
      margin: 0;
      line-height: rem(20px);
      width: rem(20px);
      height: rem(20px);
      font-size: rem(20px);
    }

    gmb-icon-button .no-change-color,gmb-icon-button .no-change-color:hover, gmb-icon-button .no-change-color:active, gmb-icon-button .no-change-color:focus {
      border-bottom: 0;
    }

  }

  .sliderCounter__input {
    @include gmb-text-bold;
    margin: 0 rem(10px);
    height: rem(40px);
    width: rem(175px);
    border: 1px solid $gmb-color-disabled;
    line-height: rem(40px);
    text-align: center;
    font-size: rem(20px);
    color: $gmb-color-default;

    input {
      padding:0;
      text-align: center;
      width: 100%;
    }
  }

  .sliderCounter__limitNumber{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: rem(9px);
  }

  /*
  &.sliderCounter--limit{

  }*/

  .sliderCounter__slider {
    display: inline-block;
    vertical-align: middle;
    height: rem(10px);
    width: 100%;
    background-color:$gmb-color-k6;
    border-radius: rem(5px);
    cursor: pointer;

    &.sliderInstalment_class{
      .sliderInstalmentColored_class.sliderLimitsColored_class{
        border: 0;
      }
    }

    .sliderInstalmentColored_class .selected{
      border-top-left-radius: rem(5px);
      border-bottom-left-radius: rem(5px);
      height: 100%;
      background-color: $gmb-color-accent;
      float: left;
    }
    .sliderInstalmentColored_class .notSelected{
      border-top-right-radius: rem(5px);
      border-bottom-right-radius: rem(5px);
      height: 100%;
      background-color: $gmb-color-k6;
      float: left;
      &:last-child{
        border-left: 0px;
      }
    }
    .sliderInstalmentColored_class .selected .tick{
      border-left:3px solid white;
      height: 74%;
      margin-top: 1px;
      margin-bottom: 1px;

      span{
        @include gmb-text-body;
        color: $gmb-color-default;
        margin-top: rem(20px);
        display: inline-block;
        transform: translateX(-50%);
      }

      &:first-child{
        border-left: 0px;
      }

      &:last-child{
        border-right: 0px;
      }

    }
    .sliderInstalmentColored_class .notSelected .notTick{
      border-right: 3px solid #909090;
      height: 74%;
      margin-top: rem(1px);
      margin-bottom: rem(1px);
      float: left;

      span{
        @include gmb-text-body;
        color: $gmb-color-default;
        margin-top: rem(20px);
        display: inline-block;
        transform: translateX(-50%);
      }

    }

    .sliderInstalmentColored_class .selected .tick.tickHidden{
      border-left: 0px !important;
    }

    .sliderInstalmentColored_class .notSelected .notTick.tickHidden{
      border-right: 0px !important;
    }

    .sliderInstalmentColored_class .notSelected .notTick.lastElement,
    .sliderInstalmentColored_class .selected .tick.lastElement {
      display: flex;
      justify-content: space-between;
      border-right: 0px;

      span {
        &:last-child {
          transform: translateX(rem(3px));
          position: absolute;
          right: 0;
        }
      }
    }

    .sliderInstalmentColored_class {
      .notSelected, .selected {
        .notTick, .tick {
          &.firstElement {
            span {
              &:nth-child(1) {
                transform: translateX(rem(-3px));
                position: absolute;
                left: 0;
              }
            }
          }
        }
      }
    }

    &.ui-slider{
      .ui-slider-handle{
        background:$gmb-color-accent!important;
        border-radius: 50%;
        height: rem($slider-cursor-size);
        width: rem($slider-cursor-size);
        top: rem(-8px);
        margin-left: rem(-11px);
        .handle-tooltip{
          display: none;
        }
      }
    }

  }

}

.sliderCounter.disabled{

  .sliderCounter__slider,.sliderCounter__slider .selected{
    background-color: $gmb-color-disabled!important;
  }
  .sliderCounter__slider span{
    color: $gmb-color-disabled!important;
  }
  .sliderCounter__buttons .sliderCounter__icon a{
    color: $gmb-color-disabled!important;
  }
  .sliderCounter__input{
    border: 1px solid $gmb-color-disabled!important;
    background: $gmb-color-k7;
  }
  .sliderCounter__input span{
    color: $gmb-color-disabled!important;
  }
  .sliderInstalmentColored_class .notSelected .notTick{
    border-right:3px solid #ffffff;
  }
  .sliderCounter__slider .ui-slider-handle{
    background: $gmb-color-disabled!important;
  }
}

.image-editor.photo-resizer-slider{

  .uc-slider .ui-slider-handle.ui-state-default, .uc-slider .ui-slider-handle.ui-state-hover, .uc-slider .ui-slider-handle.ui-state-active {
    background:$gmb-color-accent !important;
    border-radius: 50%;
    height: 24px !important;
    width: 24px !important;
    margin-top: 0px;
    top: -7px;
    margin-left: rem(-10px);
  }

  .uc-slider.ui-slider {
    position: relative;
    text-align: left;
    margin: 0 auto;
    height: rem(10px);
    width: 100%;
    background: $gmb-color-k6;
    border-radius: rem(5px);
    border: none;
    margin: rem(80px) auto rem(50px) auto;
  }

}




