gmb-step-counter{
  display: inline-block;
	width: auto;

  .stepOuterPanelGraphic{
    height: auto;
    display: flex;
    min-width: rem(130px);
    position: relative;
    justify-content: flex-start;
    z-index: 5;
    margin-bottom: 0;

    .circle {
      z-index: 5;
      position: relative;
      vertical-align: top;
      display: inline-block;
      width: rem(140px);
      &:before {
        content: '';
        background: $gmb-color-k7;
        display: block;
        height: rem(36px);
        position: absolute;
        top: 0;
        width: 100%;
        left: rem(18px);
        z-index: -1;
      }
      &:last-child{
        width: auto;
        &:before {
          content: none;
        }
      }
      .label {
        display: inline-block;
        box-sizing: border-box;
        width: rem(36px);
        height: rem(36px);
        line-height: rem(36px);
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        @include gmb-text-bold;
        font-size: rem(20px);
        padding: 0;
        color: white;
        cursor: default;
        background:$gmb-color-secondary;
        z-index: 9;
      }
      .title{
        margin-top: rem(10px);
        color: $gmb-color-secondary;
        @include gmb-text-label-upper;
        max-width: rem(126px);
      }

      /* ITEM ATTIVO */
      &.active{
        .label{
          background-color: $gmb-color-accent;
        }
        .title {
          color: $gmb-color-accent;
        }
      }

      /* ITEM COMPLETATO */
      &.done{
        .label{
          background-color:$gmb-color-default;
        }
        .title {
          color:$gmb-color-default;
        }
      }

    }


    .bar {
      display: none;

      position: relative;
      min-width: 40px;
      border-left: none;
      border-right: none;
      border-radius: 0px 0px 0px 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      top: 7px;
      padding: 0;
      background: $gmb-color-k7;
      margin-right: -4px;

      .first{
        background: #ccc;
      }

      &.active{
        background: #00aed0;
      }

      .circle-background {
        display: inline-block;
        height: inherit;
        position: relative;
        width: 20px;
        box-sizing: border-box;
        background-color: $gmb-color-k7;

        &.before {
          left: 20px;
        }

        &.after {
          right: 20px;
        }
      }
    }





  }// end stepOuterPanelGraphic


  .stepOuterPanel{

    display: inline-block;
    height: auto;
  }

  .icon-step-counter{
    font-size: rem(21px);
    line-height: rem(39px);
    width: rem(36px);
    height: rem(36px);
    display: block;
  }

}
