@import 'gmb-base-colors.scss';
@import url("/etc/designs/gimb2-it/css/fonts.css");

@mixin inline-block-element($useFullWidth: false){
  display: inline-block;
  //@include: border-box;

  @if $useFullWidth{
    width: 100%;
  }
}

// @mixin vertical-center-align{
//   display: table-cell;
//   position: relative;
//   vertical-align: middle;
// }

.vertical__align {
  display: inline-block;
  &--top { vertical-align: top; }
  &--middle { vertical-align: middle; }
  &--bottom { vertical-align: bottom; }
  &--sub { vertical-align: sub; }
}

/* RESPONSIVNESS */

%command-link-base-distance{
  padding-top: 10px;
}
